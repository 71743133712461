import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import Menu from '../components/menu'
import BookTable from '../components/BookTable'
import Layout from '../components/layout'
import SEO from '../components/seo'
import useWindowSize from '../components/useWindowSize'

const Honya = ({data}) => {
    const grouped = _.groupBy(data.allMongodbIndexPrizeItems.edges.map(book => book.node), 'year')
    const books = _.mapValues(grouped, year => _.sortBy(year, prize => Number(prize['rank'])))
    const {width} = useWindowSize();
    const sp = width < 640;

    return (
        <Layout>
            <SEO title='本屋大賞の受賞作品一覧' description='本屋大賞の受賞作品の一覧を年代順にまとめたページです'/>
            <div className='ui relaxed centered grid'>
                <Menu active={'honya'}/>
                <div className={`${sp ? 'sixteen' : 'eight'} wide column`}>
                    <BookTable books={books} prize_name='本屋大賞'/>
                </div>
            </div>
        </Layout>
    )
}

export default Honya

export const query = graphql`
{
    allMongodbIndexPrizeItems (filter: {prize_name: {eq: "honya"}}) {
        edges {
            node {
                author
                book
                year
                rank
                description
                image_path
                mongodb_id
                prize_name
            }
        }
    }
}
`
